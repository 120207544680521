import React, { Component } from "react"
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/gallery/1.jpg"
import { Button, GlobalContainer, GlobalSection } from "../components/GlobalStyle"
import Map from "../components/Map"
import RoomsGalleryColumn from "../components/RoomsGalleryColumn"
import styled from 'styled-components'
import galleryItem3 from '../images/gallery/3.jpg'
import galleryItem4 from '../images/gallery/4.jpg'
import galleryItem5 from '../images/gallery/5.jpg'
import galleryItem6 from '../images/gallery/6.jpg'
import galleryItem7 from '../images/gallery/7.jpg'
import galleryItem8 from '../images/gallery/8.jpg'
import galleryItem9 from '../images/gallery/9.jpg'
import galleryItem10 from '../images/gallery/10.jpg'
import galleryItem12 from '../images/gallery/12.jpg'
import galleryItem14 from '../images/gallery/14.jpg'
import galleryItem15 from '../images/gallery/15.jpg'
import galleryItem16 from '../images/gallery/16.jpg'
import galleryItem17 from '../images/gallery/17.jpg'
import galleryItem18 from '../images/gallery/18.jpg'
import galleryItem19 from '../images/gallery/19.jpg'
import galleryItem20 from '../images/gallery/20.jpg'
import galleryItem21 from '../images/gallery/21.jpg'
import galleryItem24 from '../images/gallery/24.jpg'
import galleryItem30 from '../images/gallery/30.jpg'
import galleryItem31 from '../images/gallery/31.jpg'
import galleryItem32 from '../images/gallery/32.jpg'
import galleryItem34 from '../images/gallery/34.jpg'
import galleryItem35 from '../images/gallery/35.jpg'
//import akwarium1 from '../images/gallery/akwarium-1.jpg'
//import akwarium2 from '../images/gallery/akwarium-2.jpg'
//import akwarium3 from '../images/gallery/akwarium-3.jpg'
//import akwarium4 from '../images/gallery/akwarium-4.jpg'
import szkoleniowa3 from '../images/gallery/szkoleniowa-3.jpg'
import szkoleniowa4 from '../images/gallery/szkoleniowa-4.jpg'
import { withTranslation } from 'react-i18next';

export const naszaPrzestrzen = [
  {
    src: galleryItem3,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem4,
    width: 1920,
    height: 1056
  },
  {
    src: galleryItem6,
    width: 1920,
    height: 1278
  },
  {
    src: galleryItem7,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem9,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem10,
    width: 1920,
    height: 1277
  },

  {
    src: galleryItem12,
    width: 1920,
    height: 1278
  },
  {
    src: galleryItem14,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem15,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem16,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem17,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem18,
    width: 1920,
    height: 1278
  },
  {
    src: galleryItem19,
    width: 1920,
    height: 1277
  },
  {
    src: galleryItem20,
    width: 1920,
    height: 1278
  },
  {
    src: galleryItem21,
    width: 1920,
    height: 1278
  },
  {
    src: galleryItem8,
    width: 1920,
    height: 2886
  },
  {
    src: galleryItem5,
    width: 1920,
    height: 2886
  },

  {
    src: galleryItem24,
    width: 1920,
    height: 1005
  },
  {
    src: szkoleniowa3,
    width: 1920,
    height: 1278
  },
  {
    src: szkoleniowa4,
    width: 1920,
    height: 1278
  },
  // {
  //   src: akwarium1,
  //   width: 1920,
  //   height: 1280
  // },
  // {
  //   src: akwarium2,
  //   width: 1920,
  //   height: 1280
  // },
  // {
  //   src: akwarium3,
  //   width: 1920,
  //   height: 1280
  // },
  // {
  //   src: akwarium4,
  //   width: 1920,
  //   height: 1280
  // },
  {
    src: galleryItem30,
    width: 900,
    height: 1200
  },
  {
    src: galleryItem31,
    width: 900,
    height: 1200
  },
  {
    src: galleryItem32,
    width: 900,
    height: 1200
  },
  {
    src: galleryItem34,
    width: 1800,
    height: 1200
  },
  {
    src: galleryItem35,
    width: 1800,
    height: 1200
  },
];
const StyledGalleryWrap = styled.div`
  position: relative;
  top: -160px;
  @media(max-width: 1000px) {
    top: -100px;
  }
`
const StyledPageHeaderDetails = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(12,0,46,0.04);
  transform: translateY(-50%);
  @media(max-width: 800px) {
    flex-direction: column;
    transform: translateY(-50px);
  }
  & > div {
    flex: 1;
    text-align: center;
    padding: 30px 10px;
    font-size: 1.4em;
    line-height: 1.1em;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 20px);
      right: 0;
      display: block;
      height: 40px;
      width: 1px;
      background-color: var(--main);
      @media(max-width: 800px) {
        display: none;
      }
    }
    &:last-of-type:after{
      display: none; 
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      opacity: .5;
      display: block;
      font-size: .6em;
    }
  }
`
const StyledGalleryButton = styled.div`
  text-align: center;
  margin-bottom: 100px;
`

class Galeria extends Component {
  render() {
    return (
      <>
        <SEO
          title="Nasza przestrzeń"
          description="Wierzymy, że do wymyślenia rzeczy wielkich potrzebna jest odpowiednia przestrzeń, która pozwoli skupić się na tym co najważniejsze. Dlatego stworzyliśmy przyjazne środowisko, które sprawi, że Twoja praca stanie się przyjemna i efektywna."
        />
        <PageHeader image={headerImage} gallery>
          <h1 className="gallery-header" data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('header.spaceTitle')}</h1>
          <p className="gallery-text">{this.props.t('header.spaceText1')}<br/>	{this.props.t('header.spaceText2')}</p>
        </PageHeader>
        <GlobalSection bg="#F4F4F4">
          <StyledGalleryWrap>
            <GlobalContainer>
              <div>
                <RoomsGalleryColumn photos={naszaPrzestrzen} />
              </div>
            </GlobalContainer>
          </StyledGalleryWrap>
          <StyledGalleryButton>
            <GlobalContainer>
              <Button to="/rezerwacja">{this.props.t('common.reservation')}</Button>
            </GlobalContainer>
          </StyledGalleryButton>
        </GlobalSection>
        <Map/>
      </>
    )
  }
}

export default withTranslation()(Galeria)
